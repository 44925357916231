import React from 'react'

import { courseInfo } from '../../course_content/meta/CourseInfo'

import { msalConfig, loginRequest } from '../utility/auth/msalConfig';
import { MsalProvider } from '../utility/auth/msalContext';

import classes from '../styles/course-info.module.sass'


import Wrapper from '../components/wrapper'


export default ({ data }) =>
{

    return (
        <MsalProvider
            config={msalConfig}
            scopes={loginRequest}
            >
            <Wrapper>
                <div className={classes.container}>
                    <h1 className={classes.pagetitle}>About the course</h1>
                    <div className={classes.infocontainer}>
                        {courseInfo.map((item, index) => (
                            <div className={classes.infotext}>
                                <h3 className={classes.sectiontitle}>{item.title}</h3>
                                <p className={classes.sectiontext}
                                    dangerouslySetInnerHTML={{__html: item.text}}>
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </Wrapper>
        </MsalProvider>
    )
}
